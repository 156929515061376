// DOC: https://docs.google.com/spreadsheets/d/12U-64hE5fd9T036XapZSgjCs5TEjKN9ZzCN1lt2epZ0/edit?gid=0#gid=0
const CATEGORY = {
  DEFAULT: 'Payroll',
  PayrollLogin: 'PayrollLogin',
};

const ACTION = {
  PayrollOpened: 'PayrollOpened',
  PayrollClosed: 'PayrollClosed',
  PayrollAdded: 'PayrollAdded',
  PayrollRegisterDeleted: 'PayrollRegisterDeleted',
  PayrollConceptAdded: 'PayrollConceptAdded',
  PayrollFormulaConceptAdded: 'PayrollFormulaConceptAdded',
  PayrollConceptDeleted: 'PayrollConceptDeleted',
  OrganigramAdded: 'OrganigramAdded',
  OrganigramDeleted: 'OrganigramDeleted',
  CalendarAdded: 'CalendarAdded',
  CalendarDeleted: 'CalendarDeleted',
  EmployeeAdded: 'EmployeeAdded',
  EmployeeDeleted: 'EmployeeDeleted',
  EmployeePayrollDataAdded: 'EmployeePayrollDataAdded',
  EmployeePayrollDataDeleted: 'EmployeePayrollDataDeleted',
  EmployeePayrollImssDataAdded: 'EmployeePayrollImssDataAdded',
  EmployeePayrollImssDataDeleted: 'EmployeePayrollImssDataDeleted',
  EmployeePayrollMovementAdded: 'EmployeePayrollMovementAdded',
  EmployeePayrollMovementDeleted: 'EmployeePayrollMovementDeleted',
  SdiCalculated: 'SdiCalculated',
  AffiliationMovementsAdded: 'AffiliationMovementsAdded',
  AffiliationMovementsDeleted: 'AffiliationMovementsDeleted',
  SbcFixedCalculated: 'SbcFixedCalculated',
  StampingSuccess: 'StampingSuccess',
  PayStubSent: 'PayStubSent',
  PayStubSentWorky: 'PayStubSentWorky',
  LoginSuccess: 'LoginSuccess',
  LoginFailed: 'LoginFailed',
  CalculatedPayrollSuccess: 'CalculatedPayrollSuccess',
  CalculatedPayrollFailed: 'CalculatedPayrollFailed',
  StampingFailed: 'StampingFailed',
  ConsultedReportPayroll: 'ConsultedReportPayroll',
  PayrollReportDownloaded: 'PayrollReportDownloaded',
  ConsultedReportIMSS: 'ConsultedReportIMSS',
  IMSSReportDownloaded: 'IMSSReportDownloaded',
  ConsultedReportMonthly: 'ConsultedReportMonthly',
  MonthlyReportDownloaded: 'MonthlyReportDownloaded',
  ConsultedReportClosing: 'ConsultedReportClosing',
  ClosingReportDownloaded: 'ClosingReportDownloaded',
};

export { CATEGORY, ACTION };
